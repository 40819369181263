import React from "react"
import { graphql } from "gatsby"
import Iubenda from "react-iubenda-policy"

import Layout from "../layout"
import SEO from "../seo"
import { Container, Row, Col } from "react-bootstrap"
import i18n from "../../helpers/i18n"

import "../../scss/pages/contact.scss"

const getArtwork = (location, lang, allPrismicArtwork) => {
  var artwork = false
  if (!location.search) {
    return ""
  }

  const getParam = /^(\?|&)artwork=([^&]+)$/
  const matches = location.search.match(getParam)
  const uid = matches && matches.length > 2 ? matches[2] : false
  if (!uid) {
    return ""
  }

  allPrismicArtwork.edges.forEach(edge => {
    if (edge.node.uid !== uid || edge.node.lang !== lang) {
      return ""
    }

    const artistName = edge.node.data.artist.document.data.name.text
    const artworkYear = edge.node.data.year
    const artworkName = edge.node.data.title.text

    artwork = [artistName, artworkYear, artworkName].join(", ")
  })

  return artwork
}

const getPageIntent = (location, lang) => {
  var pageIntent = false
  if (!location.search) {
    return pageIntent
  }

  const getParam = /^(\?|&)pageIntent=([^&]+)$/
  const matches = location.search.match(getParam)
  const intentId = matches && matches.length > 2 ? parseInt(matches[2]) : false

  switch (intentId) {
    case 1:
      return i18n.contactPageIntent1
    case 2:
      return i18n.contactPageIntent2
    default:
      return ""
  }
}

const Page = ({ location, data: { prismicContact, allPrismicArtwork } }) => {
  const { lang, data } = prismicContact
  i18n.setLanguage(lang)

  const subject =
    getArtwork(location, lang, allPrismicArtwork) ||
    getPageIntent(location, lang) ||
    i18n.contactDefaultSubject

  return (
    <Layout lang={lang}>
      <SEO title={data.title.text} />
      <div className="contact-heading">
        <Container>
          <h1 className="gls-title">{data.title.text}</h1>
          <h2 className="gls-subtitle">{data.subtitle.text}</h2>
        </Container>
      </div>
      <Container>
        <Row>
          <Col md="7">
            <div className="contact-form">
              <form
                method="POST"
                action="https://formspree.io/f/mleaenew"
              >
                <input
                  key="firstname"
                  type="text"
                  name="firstname"
                  placeholder={i18n.contactFirstname}
                  required
                />
                <input
                  key="lastname"
                  type="text"
                  name="lastname"
                  placeholder={i18n.contactLastname}
                  required
                />
                <input
                  key="subject"
                  type="text"
                  name="subject"
                  value={subject}
                  readOnly
                />
                <input
                  key="email"
                  type="email"
                  name="_replyto"
                  placeholder={i18n.contactEmail}
                  required
                />
                <input
                  key="telephone"
                  type="text"
                  name="telephone"
                  placeholder={i18n.contactTelephone}
                />
                <textarea
                  name="message"
                  placeholder={i18n.contactMessage}
                  required
                ></textarea>
                <label className="privacy-checkbox">
                  <input type="checkbox" required />
                  <span>{i18n.contactPrivacyPolicy}</span>
                  <Iubenda id={lang === "en-us" ? "42957726" : "20934711"} />
                </label>
                <button className="gls-button" type="submit">
                  {i18n.contactSubmit}
                </button>
              </form>
            </div>
          </Col>
          <Col md="5">
            <div className="contact-details">
              <strong>{i18n.contactTelephone}</strong>
              <a href={`tel:${data.contact_phone}`}>{data.contact_phone}</a>
              <strong>{i18n.contactEmail}</strong>
              <a href={`mailto:${data.contact_email}`}>{data.contact_email}</a>
            </div>
            <img
              className="right-image"
              src={data.image_right.url}
              alt={data.image_right.alt}
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query ContactByLocale($lang: String!) {
    prismicContact(lang: { eq: $lang }) {
      lang
      data {
        title {
          text
        }
        subtitle {
          text
        }
        contact_email
        contact_phone
        image_right {
          url
          alt
        }
      }
    }
    allPrismicArtwork {
      edges {
        node {
          uid
          lang
          data {
            year
            artist {
              document {
                ... on PrismicArtist {
                  data {
                    name {
                      text
                    }
                  }
                }
              }
            }
            title {
              text
            }
          }
        }
      }
    }
  }
`
